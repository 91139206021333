export default {
  "main_page": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
    "how_we_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosotros"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
    "first_logistics_storage_platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plataforma #1 de España de almacenamiento logístico."])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecta Ahora"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecta"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacena"])},
    "deliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega"])},
    "join_spains_leading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Únete a la plataforma #1 de almacenamiento logístico en España y encuentra tu espacio ideal!"])},
    "logistic_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento Logístico"])},
    "e_commerce_logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logística E-Commerce"])},
    "customs_warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento  Aduanero"])},
    "OPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones 24/7"])},
    "ADR_torage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento de Materiales Peligrosos (ADR)"])},
    "cold_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento en Frío"])},
    "frozen_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento Congelado"])},
    "pharma_torage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento Farmacéutico"])},
    "many_more_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+MÁS"])},
    "join_and_discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únete Ahora"])},
    "keep_track_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigue el ritmo de tu negocio:"])},
    "store_and_deliver_effortlessly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["almacena y entrega sin complicaciones"])},
    "find_space_and_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuentra espacio para almacenar en un clic, mantén control de tu inventario y entregas en un solo sitio."])},
    "connect_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecta Ahora"])},
    "our_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestros clientes nos "])},
    "review_from_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviews de clientes"])},
    "carlos_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trakilos nos ha permitido simplificar el proceso de logística. Tras integrar nuestra tienda online con su sistema solo nos concentramos en crecer. Ser capaces de seguir cada venta y elegir donde almacenamos para reducir costes y simplificar la logística ha sido la clave para nuestro éxito"])},
    "javier_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar espacio de almacenamiento durante el peak era complicado hasta que comenzamos a trabajar con Trakilos. Ahora podemos almacenar fácilmente parte de nuestro inventario antes de enviarlo a otras ciudades. Saber que podemos encontrar espacio en cualquier momento sin tener que esperar para tener respuesta, ha hecho que nuestros envíos nacionales e internacionales sean mucho más sencillos."])},
    "laura_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tener todo en un solo lugar es genial. Antes, gestionábamos nuestro comercio electrónico, almacenamiento, entregas, seguimiento y facturación en diferentes plataformas, lo que dificultaba que nuestro equipo estuviera al tanto. Incluso perdimos ventas debido a retrasos. Ahora que todo está consolidado, podemos supervisar fácilmente nuestras ventas, rastrear las entregas y estar al día sobre nuestro stock y el estado de las entregas."])},
    "cities_currently_serving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudades a Las Que Servimos Actualmente"])},
    "cities_we_are_currently_serving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudades donde llegamos"])},
    "barcelona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcelona"])},
    "madrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madrid"])},
    "seville": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sevilla"])},
    "valencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valencia"])},
    "saragossa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaragoza"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximamente..."])},
    "streamline_your_logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡ Simplifica tu logística con una solución segura, transparente e innovadora !"])},
    "e_tailored_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-commerce/Soluciones Personalizadas B2C"])},
    "b2b_tailored_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluciones Personalizades B2B"])},
    "connect_with_our_platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecta con nuestra platforma"])},
    "storage_immediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacena inmediatamente"])},
    "keep_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento 24/7"])},
    "pack_&_ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recogidas, Empaques y Envios"])},
    "deliver_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregas"])},
    "choose_your_best_warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige tu espacio"])},
    "keep_your_logistics_moving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantén tu logística en movimiento,"])},
    "simple_integration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["integración simple y seguimiento ilimitado 24/7"])},
    "join_spains_top_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únete a la plataforma líder en almacenamiento y entrega para empresas en España. Simplificamos la logística conectando tus necesidades con soluciones, todo en un mismo lugar."])},
    "why_trakilos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Por qué Trakilos?"])},
    "discover_new_growth_opportunities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubre nuevas oportunidades de crecimiento."])},
    "carousel_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Te resulta increíble? Piensa en lo fácil que es pedir comida con UberEats: ¡pides, pagas y listo! Ahora imagina esa misma comodidad para tu negocio. Inicia sesión en tu cuenta, integra nuestra plataforma y observa cómo hacemos de tu logística algo sencillo y eficiente. ¡Hagamos que gestionar tu negocio sea tan fácil como pedir la cena!"])},
    "get_started_in_just_3_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empieza en solo 3 pasos:"])},
    "are_you_a_storage_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tienes un almacén?"])},
    "start_growing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empieza a crecer"])},
    "parnet_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colabora con nosotros"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "what_are_you_looking_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué estás buscando?"])},
    "hassle_free_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento Fácil"])},
    "e_commerce_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluciones E-Commerce"])},
    "General_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información General"])},
    "im_a_storage_provider_eager_to_collaborate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy proveedor de almacenamiento interesado en colaborar"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
    "type_your_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe tu mensaje"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
  },
  "carousel_text": {
    "immediate_availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISPONIBILIDAD INMEDIATA"])},
    "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIMPLE"])},
    "safe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEGURO"])},
    "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRECE"])},
    "immediate_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Así de fácil! Espacios de almacenamiento y soluciones para e-commerce disponibles AHORA MISMO.  "])},
    "immediate_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di adiós a las esperas y a los correos interminables. Con TRAKILOS, es muy sencillo: regístrate, conéctate y comienza a almacenar ¡hoy mismo!"])},
    "simple_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Te resulta increíble? Piensa en lo fácil que es pedir comida con UberEats: ¡pides, pagas y listo! "])},
    "simple_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora imagina esa misma comodidad para tu negocio. Inicia sesión en tu cuenta, integra nuestra plataforma y observa cómo hacemos de tu logística algo sencillo y eficiente. "])},
    "simple_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Hagamos que gestionar tu negocio sea tan fácil como pedir la cena!"])},
    "safe_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Tu tranquilidad es lo primero! Tu inventario es importante para ti y por eso también lo es para nosotros. "])},
    "safe_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con nuestras soluciones de almacenamiento, gestiona tu logística con total confianza, sabiendo que tus productos están siempre en manos seguras y en espacios que cumplen con nuestros rigurosos estándares de calidad"])},
    "grow_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deja la logística en manos de los expertos y concéntrate en lo que realmente es importante para ti: "])},
    "grow_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUMENTA tus ventas y haz CRECER tu empresa."])}
  },
  "footer": {
    "reach_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacta con Nosotros"])},
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidad"])},
    "cookies_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Cookies"])},
    "terms_&_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y Condiciones"])},
    "all_rights_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los Derechos Reservados. "])}
  }
}