<template>
  <div id="app">
    <component :is="layout">
      <NavBar/>
      <router-view/>
      <Footer/>
    </component>
  </div>

</template>
<script>

import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  computed: {
    layout() {
      return this.$route.meta.layout ? `${this.$route.meta.layout}-layout` : 'default-layout'
    }
  },
  components: {
    Footer,
    NavBar
  }


}
</script>

<style lang="scss">
@font-face {
  font-family: "Freight Sans Book";
  src: url('~@/assets/fonts/FreightSansBook/FreightSans-Book.ttf');
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Freight Sans Book", sans-serif;
}
#app {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
</style>
