export default {
  "main_page": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "how_we_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How we work"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "first_logistics_storage_platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The #1 Logistics Storage Platform in Spain!"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage"])},
    "deliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliver"])},
    "join_spains_leading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Spain's leading logistics storage platform and find your ideal space solution!"])},
    "logistic_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistic Storage"])},
    "e_commerce_logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Commerce Logistics"])},
    "customs_warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customs Warehouse"])},
    "OPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7 OPS"])},
    "ADR_torage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADR Storage"])},
    "cold_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold Storage"])},
    "frozen_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frozen Storage"])},
    "pharma_torage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pharma Storage"])},
    "many_more_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Many more services instead of the"])},
    "join_and_discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join and Discover"])},
    "keep_track_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep track of your business:"])},
    "store_and_deliver_effortlessly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["store and deliver effortlessly with Trakilos"])},
    "find_space_and_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find space and storage in just a click, keep tabs on your stock and deliveries all in one place."])},
    "connect_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect Now"])},
    "our_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our clients "])},
    "review_from_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review from clients"])},
    "carlos_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrating our e-commerce store with TRAKILOS system has allowed us to focus on expanding our business. Being able to track every sale and choose the best storage locations has really helped us reduce shipping costs and improve logistics."])},
    "javier_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding storage during peak season was tough until we partnered with Trakilos. Now, we can easily store part of our inventory before shipping it to other cities. Knowing we can find space anytime, without long wait times for answers, has made our national and international shipping so much easier."])},
    "laura_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having everything in one place is fantastic. Before, we managed our e-commerce, storage, deliveries, tracking and billing on different platforms, which made it hard for our team to keep track. We even lost sales because of delays. Now that everything is consolidated, we can easily monitor our sales, track deliveries, and stay updated on our stock and delivery statuses. "])},
    "cities_currently_serving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cities We Are Currently Serving"])},
    "cities_we_are_currently_serving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cities We Are Currently Serving"])},
    "barcelona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcelona"])},
    "madrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madrid"])},
    "seville": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seville"])},
    "valencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valencia"])},
    "saragossa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saragossa"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon..."])},
    "streamline_your_logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamline your logistics with secure, transparent, and innovative solutions!"])},
    "e_tailored_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-commerce / B2C Tailored Solutions"])},
    "b2b_tailored_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B Tailored Solutions"])},
    "connect_with_our_platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect with our platform"])},
    "storage_immediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage Immediately"])},
    "keep_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep track 24/7"])},
    "pack_&_ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick, Pack & Ship"])},
    "deliver_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliver"])},
    "choose_your_best_warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your best warehouse"])},
    "keep_your_logistics_moving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep your logistics moving with"])},
    "simple_integration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simple integration and unlimited tracking 24/7"])},
    "join_spains_top_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Spain's top storage and delivery platform for businesses, where we streamline logistics by connecting NEEDS with SOLUTIONS - all in one place."])},
    "why_trakilos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Trakilos ?"])},
    "discover_new_growth_opportunities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover new growth opportunities"])},
    "carousel_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Think it sounds too good to be true? Remember how easy UberEats made ordering food? Order, pay, and enjoy, right? Now, imagine that simplicity for your business. Just log into your dashboard, integrate our platform, and watch us simplify your logistics. Let’s make running your business as easy as ordering dinner!"])},
    "get_started_in_just_3_steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started in just 3 steps:"])},
    "are_you_a_storage_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you a storage provider ?"])},
    "start_growing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start growing"])},
    "parnet_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parnet with us"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "what_are_you_looking_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you looking for?"])},
    "hassle_free_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hassle-Free Storage"])},
    "e_commerce_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Commerce Solutions"])},
    "General_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Information"])},
    "im_a_storage_provider_eager_to_collaborate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’m a storage provider eager to collaborate."])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "type_your_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type your message"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
  },
  "carousel_text": {
    "immediate_availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMMEDIATE AVAILABILITY"])},
    "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIMPLE"])},
    "safe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAFE"])},
    "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GROW"])},
    "immediate_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just like that! Storage space and e-commerce solutions available RIGHT NOW. "])},
    "immediate_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Say goodbye to waiting for availableness and endless email threads. With TRAKILOS, it’s simple: register, connect, and start storing today!"])},
    "simple_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Think it sounds too good to be true? Remember how easy UberEats made ordering food? "])},
    "simple_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order, pay, and enjoy, right? Now, imagine that simplicity for your business. Just log into your dashboard, integrate our platform, and watch us simplify your logistics. "])},
    "simple_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s make running your business as easy as ordering dinner!"])},
    "safe_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’ve got your back covered! Your inventory matters, and that’s why it matters to us too. "])},
    "safe_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With our storage solutions, you can manage your logistics confidently, knowing your products are only stored and handled in spaces that pass our verification process. "])},
    "grow_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave the logistics to the experts and focus on what’s really important for you: "])},
    "grow_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INCREASING your sales and GROWING your company."])}
  },
  "partner_carousel_text": {
    "discover_new_growth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover new growth opportunities."])}
  },
  "footer": {
    "reach_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach us"])},
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "cookies_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies Policy"])},
    "terms_&_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
    "all_rights_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" All rights reserved."])}
  }
}