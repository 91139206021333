<template>
  <div class="footer_container">
    <div class="footer_content">
      <div class="footer_logo"></div>
      <div class="footer_links">

        <div class="footer_link-block">
          <h4 class="footer_link-title">{{$t('footer.reach_us')}}</h4>
          <ul class="footer_link-list">
            <li><span class="footer_link-icon phone"></span>+34 673 04 95 55</li>
            <li><span class="footer_link-icon message"></span>info@trakilos.com</li>
          </ul>
        </div>

        <div class="footer_link-block">
          <h4 class="footer_link-title">{{$t('footer.legal')}}</h4>
          <ul class="footer_link-list">
            <li>{{$t('footer.privacy_policy')}}</li>
            <li>{{$t('footer.cookies_policy')}}</li>
            <li>{{$t('footer.terms_&_conditions')}}</li>
          </ul>
        </div>

      </div>
    </div>

    <div class="footer_copyright">Copyright © {{$t('footer.all_rights_reserved')}}</div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {

    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  &_container {
    width: 100%;
    padding: 60px 100px 30px;
    background: #1E1E1C;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1680px) {
      padding: 50px 80px 20px;
    }
    @media screen and (max-width: 768px) {
      padding: 30px 70px 30px;
    }
    @media screen and (max-width: 430px) {
      padding: 25px 30px;
    }
  }
  &_logo {
    width: 222px;
    height: 72px;
    background: url("~@/assets/icons/footer_logo.svg") no-repeat center;
    @media screen and (max-width: 1440px) {
      width: 180px;
      height: 70px;
      background-size: contain;
    }
    @media screen and (max-width: 430px) {
      width: 150px;
      height: 60px;
    }
  }
  &_content {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
  }
  &_links {
    display: flex;
    justify-content: space-between;
    max-width: 50%;
    width: 100%;
    margin: 0 180px;
    @media screen and (max-width: 1680px) {
      margin: 0 150px;
    }
    @media screen and (max-width: 1440px) {
      margin: 0 120px;
    }
    @media screen and (max-width: 768px) {
      margin: 20px;
      display: flex;
      justify-content: space-between;
      max-width: 100%;
    }
    @media screen and (max-width: 430px) {
      margin: 15px 0;
    }

  }
  &_link {
    &-block {
      @media screen and (max-width: 768px) {
        width: 290px;
        padding-left: 35px;
      }
      @media screen and (max-width: 430px) {
        padding-left: 5px;
      }
    }
    &-title {
      color: #FFF;
      font-size: 32px;
      font-weight: bold;
      line-height: normal;
      @media screen and (max-width: 1440px) {
        font-size: 28px;
      }
      @media screen and (max-width: 430px) {
        font-size: 22px;
      }
    }
    &-icon {
      width: 32px;
      height: 32px;
      display: flex;
      margin-right: 30px;
      @media screen and (max-width: 430px) {
        margin-right: 15px;
      }

      &.phone {
        background: url("~@/assets/icons/phone_call.svg");
      }
      &.message {
        background: url("~@/assets/icons/email_envelope.svg");
      }
    }
  }
  &_link-list {
    padding-left: 0;
    & li {
      display: flex;
      align-items: center;
      list-style-type: none;
      color: #FFFFFF;
      padding: 15px 0;
      @media screen and (max-width: 1440px) {
        padding: 10px 0;
      }
    }
  }
  &_copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
